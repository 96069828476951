import React from 'react';
import { Link } from 'react-router-dom';
import {
  Col,
  Collapse,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  Nav,
  Navbar,
  NavbarBrand,
  NavLink,
  UncontrolledDropdown,
} from 'reactstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faUserCircle } from '@fortawesome/free-solid-svg-icons';
import styled, { css } from 'styled-components/macro';
import { theme } from 'styled-tools';
import { down, up } from 'styled-breakpoints';
import { AuthContext } from 'contexts/authContext';
import logoImg from 'assets/images/rent-safe-logo.png';
import { getRedirectPath } from 'utils/roles';
import { UnstyledList } from 'components/Typography';

const LogoImg = styled.img`
  height: 3.25rem;

  ${down('lgMobile')} {
    height: 2.5rem;
  }
`;

const StyledLink = styled(NavLink)`
  color: ${theme('colors.primary')} !important;
`;

const UserNavDetails = styled.span`
  margin-left: 0.5rem;
  margin-right: 0.5rem;
`;

const StyledNavbar = styled(Navbar)`
  background: ${theme('colors.white')};
  box-shadow: ${theme('boxShadow')};
  height: 5rem;
  padding: 0.5rem 1rem;
`;

const paddingLess = css`
  &&& {
    padding: 0;
  }
`;

const LogoSlogan = styled(Col)`
  ${paddingLess}
`;

const LogoSloganWrapper = styled(Col).attrs({
  xs: 12,
  md: 6,
})`
  ${paddingLess}

  ${down('lgMobile')} {
    display: flex;
    justify-content: center;
  }
`;

const MobileDropdownWrapper = styled.div`
  display: block;
  ${up('smTablet')} {
    display: none;
  }
  
  position: absolute;
  right: 1rem;
`;

const MobileDropdownButton = styled(DropdownToggle)`
  &&& {
    background: #fff;
  }
`;

function DropdownMenuWrapper() {
  return (
    <DropdownMenu right tabIndex={0}>
      <DropdownItem onClick={() => (window.location.href = '/account')}>
        <StyledLink className='nav-link' active>
          Edit Profile
        </StyledLink>
      </DropdownItem>
      <DropdownItem onClick={() => (window.location.href = '/logout')}>
        <StyledLink className='nav-link' active>
          Logout
        </StyledLink>
      </DropdownItem>
    </DropdownMenu>
  );
}

const Navigation = () => {
  const { isAuthenticated } = React.useContext(AuthContext);

  return (
    <StyledNavbar light fixed={'top'} expand='md'>
      <LogoSlogan>
        <LogoSloganWrapper>
          <NavbarBrand tag={Link} to={getRedirectPath()}>
            <LogoImg src={logoImg} alt='rentsafe logo' />
          </NavbarBrand>
        </LogoSloganWrapper>
      </LogoSlogan>

      {isAuthenticated && (
        <MobileDropdownWrapper>
          <UnstyledList>
            <UncontrolledDropdown nav inNavbar>
              <MobileDropdownButton>
                <span className='navbar-toggler-icon' />
              </MobileDropdownButton>
              <DropdownMenuWrapper />
            </UncontrolledDropdown>
          </UnstyledList>
        </MobileDropdownWrapper>
      )}
      {isAuthenticated && (
        <Collapse navbar>
          <Nav className='rightNav ml-auto' navbar>
            <UncontrolledDropdown nav inNavbar>
              <DropdownToggle nav caret>
                <FontAwesomeIcon
                  icon={faUserCircle}
                  alt='My Account Dropdown'
                  role='presentation'
                />
                <UserNavDetails>MY ACCOUNT</UserNavDetails>
              </DropdownToggle>
              <DropdownMenuWrapper />
            </UncontrolledDropdown>
          </Nav>
        </Collapse>
      )}
    </StyledNavbar>
  );
};

export default Navigation;
