import ReactGA from 'react-ga';

const trackingId = process.env.REACT_APP_GOOGLE_ANALYTICS_ID;

export function gaEvent(data) {
  if (trackingId) {
    ReactGA.event(data);
  }
}

export function gaModalView(data) {
  if (trackingId) {
    ReactGA.modalview(data);
  }
}

export function gaSet(fieldsObject, trackerNames) {
  if (trackingId) {
    ReactGA.set(fieldsObject, trackerNames);
  }
}

export function gaSetUserId(userId) {
  if (trackingId) {
    gaSet({ userId });
  }
}

export function sendPageView(location) {
  if (trackingId) {
    // Wrap in a setTimeout so that the pageview event will
    // hopefully occur after react-helmet updates the page title.
    // Without this, the page title shown in GA will often be the previous page.
    // https://github.com/nfl/react-helmet/issues/189#issuecomment-336292754
    setTimeout(() => {
      ReactGA.set({ page: location.pathname });
      ReactGA.pageview(location.pathname);
    }, 0);
  }
}
