import styled, { css } from 'styled-components/macro';
import { ifProp, theme } from 'styled-tools';
import { rem } from 'polished';
import { down } from 'styled-breakpoints';

const H1 = styled.h1`
  font: bold 2rem ${theme('fonts.heading')};
  margin-bottom: 2rem;
  text-align: center;
  color: ${theme('colors.primary')};

  ${ifProp(
    'subtext',
    css`
      margin-bottom: 0.5rem;
    `
  )}

  ${down('smTablet')} {
    font: bold 1.3rem ${theme('fonts.heading')};
  }
`;

const H2 = styled.h2`
  font: bold ${rem('26px')} ${theme('fonts.heading')};
  color: ${theme('colors.primary')};
`;

const H3 = styled.h3`
  font: bold ${rem('21px')} ${theme('fonts.heading')};
  color: ${theme('colors.primary')};
`;

const H4 = styled.h4`
  font: bold ${rem('18.72px')} ${theme('fonts.heading')};
  color: ${theme('colors.primary')};
`;

const H5 = styled.h5`
  font: bold 1rem ${theme('fonts.heading')};
  text-transform: uppercase;
  color: ${theme('colors.primary')};
`;

const P = styled.p`
  font-size: ${rem('14px')};
  line-height: ${rem('18px')};
  color: ${theme('colors.primary')};

  ${ifProp(
    'center',
    css`
      text-align: center;
    `
  )}
    ${ifProp(
      'bold',
      css`
        font-weight: bold;
      `
    )}
    ${ifProp(
      'italic',
      css`
        font-style: italic;
      `
    )}
`;

const UnstyledList = styled.ul`
  list-style-type: none;
  padding-left: 0;
  margin-bottom: 0;
  color: ${theme('colors.primary')};
`;

export { H1, H2, H3, H4, H5, P, UnstyledList };
