import styled, { css } from 'styled-components/macro';
import { theme, ifProp } from 'styled-tools';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheckCircle } from '@fortawesome/free-solid-svg-icons';

const CheckIcon = styled(FontAwesomeIcon).attrs({
  icon: faCheckCircle,
  alt: 'Matched',
})`
  color: ${theme('colors.success')};
  margin-right: 0.375rem;

  ${ifProp(
    { type: 'list' },
    css`
      margin-top: 0.1875rem;
    `
  )}
`;

export default CheckIcon;
