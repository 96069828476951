import {
  faCheck,
  faClock,
  faHistory,
  faTimes,
  faUserClock,
  faEnvelope,
} from '@fortawesome/free-solid-svg-icons';
import styled from 'styled-components/macro';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

const StyledIcon = styled(FontAwesomeIcon)`
  margin-right: 0.3rem;
`;

const CanceledIcon = styled(StyledIcon).attrs({
  icon: faTimes,
  alt: 'Cancel',
  role: 'presentation',
})``;

const ApprovedIcon = styled(StyledIcon).attrs({
  icon: faCheck,
  alt: 'Approve',
  role: 'presentation',
})``;

const InProgressIcon = styled(StyledIcon).attrs({
  icon: faClock,
  alt: 'Clock',
  role: 'presentation',
})``;

const NeedsReviewIcon = styled(StyledIcon).attrs({
  icon: faUserClock,
  alt: 'Clock',
  role: 'presentation',
})``;

const NeedsUpdatesIcon = styled(StyledIcon).attrs({
  icon: faHistory,
  alt: 'Clock',
  role: 'presentation',
})``;

const NewIcon = styled(StyledIcon).attrs({
  icon: faEnvelope,
  alt: 'New',
  role: 'presentation',
})``;

const OpenIcon = styled(StyledIcon).attrs({
  icon: faClock,
  alt: 'Open',
  role: 'presentation',
})``;

const DeniedIcon = styled(StyledIcon).attrs({
  icon: faTimes,
  alt: 'Denied',
  role: 'presentation',
})``;

export {
  ApprovedIcon,
  CanceledIcon,
  InProgressIcon,
  NeedsReviewIcon,
  NeedsUpdatesIcon,
  NewIcon,
  OpenIcon,
  DeniedIcon,
};
