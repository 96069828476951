import React from 'react';
import { FadeLoader } from 'react-spinners';
import styled from 'styled-components/macro';

const LoaderWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  min-height: 100px;
`;

const Loader = () => (
  <LoaderWrapper>
    <FadeLoader />
  </LoaderWrapper>
);

export default Loader;
