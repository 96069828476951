import React from 'react';
import PropTypes from 'prop-types';
import { Notifier } from '@airbrake/browser';
import ServerErrorPage from 'routes/ErrorPages/ServerErrorPage';
import { getUserData, isAuthenticated } from 'utils/auth';

class ErrorBoundary extends React.Component {
  constructor(props) {
    super(props);
    this.state = { hasError: false };

    // Initialize Airbrake if env vars are set for error monitoring.
    if (process.env.REACT_APP_AIRBRAKE_ID && process.env.REACT_APP_AIRBRAKE_KEY) {
      this.airbrake = new Notifier({
        projectId: process.env.REACT_APP_AIRBRAKE_ID,
        projectKey: process.env.REACT_APP_AIRBRAKE_KEY,
        environment: process.env.REACT_APP_ENVIRONMENT || 'local',
      });
    }
  }

  static getDerivedStateFromError(error) {
    // Update state so the next render will show the fallback UI.
    return { hasError: true };
  }

  componentDidCatch(error, info) {
    // Display fallback UI
    this.setState({ hasError: true });

    // Send error to Airbrake if enabled, otherwise log error to console.
    if (this.airbrake) {
      const user = isAuthenticated() && getUserData();
      this.airbrake.notify({
        error,
        params: { info, user },
        context: { user },
      });
    }
  }

  render() {
    if (this.state.hasError) {
      // Render fallback UI, serving up the error page.
      const ErrorComponent = this.props.errorComponent || ServerErrorPage;
      return <ErrorComponent />;
    }
    return this.props.children;
  }
}

ErrorBoundary.propTypes = {
  errorComponent: PropTypes.elementType,
};

export default ErrorBoundary;
