import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { Redirect, Route, useHistory } from 'react-router-dom';
import { isAdmin } from 'utils/roles';
import { getUserData, isAuthenticated } from 'utils/auth';
import {
  ROLE_USER,
  ROLE_PROPERTY_MANAGER,
  ROLE_RENTSAFE_ADMIN,
  ROLE_SUPER_ADMIN,
  userHasRole,
} from 'utils/roles';

const propTypes = {
  role: PropTypes.oneOf([ROLE_USER, ROLE_PROPERTY_MANAGER, ROLE_RENTSAFE_ADMIN, ROLE_SUPER_ADMIN]),
  redirectTo: PropTypes.string,
};

function PrivateRoute({ role, component: Component, redirectTo, ...rest }) {
  const user = getUserData();
  const history = useHistory();

  useEffect(() => {
    if (user && !user.isVerified) {
      history.push('/verify_email');
    }
  }, [user, history]);

  return (
    <Route
      {...rest}
      render={props => {
        if (isAuthenticated() && (userHasRole(role) || isAdmin())) {
          return <Component {...props} />;
        } else if (isAuthenticated()) {
          return <Redirect to='/403' />;
        } else {
          return (
            <Redirect
              to={{
                pathname: redirectTo || '/login',
                state: { from: props.location },
              }}
            />
          );
        }
      }}
    />
  );
}

PrivateRoute.propTypes = propTypes;

export default PrivateRoute;
