import React from 'react';
import styled from 'styled-components/macro';
import { theme } from 'styled-tools';
import { up } from 'styled-breakpoints';
import { withContext } from 'utils/hocs';
import Routes from './routes';
import { FlashProvider } from 'contexts/flashContext';
import Footer from 'components/Footer';
import Nav from './components/Nav';
import { getUserData } from 'utils/auth';
import GoogleAnalyticsListener from 'components/GoogleAnalyticsListener';
import './styles/index.scss';
import ErrorBoundary from 'components/ErrorBoundary';

const SiteWrapper = styled.div`
  background: ${theme('colors.secondary.regular')};
  padding-top: 5rem;
  min-height: calc(100% - 5.938rem);

  ${up('lgTablet')} {
    min-height: calc(100% - 3.313rem);
  }
`;

function App() {
  const userData = getUserData();

  return (
    <GoogleAnalyticsListener
      trackingId={process.env.REACT_APP_GOOGLE_ANALYTICS_ID}
      userId={userData?.id}
    >
      <SiteWrapper>
        <Nav />
        <ErrorBoundary>
          <Routes />
        </ErrorBoundary>
      </SiteWrapper>
      <Footer />
    </GoogleAnalyticsListener>
  );
}

export default withContext(App, FlashProvider);
