import { theme } from 'styled-tools';

const colors = {
  primary: '#353A5A',
  brandRed: '#FF474e',
  secondary: {
    dark: '#16191e',
    medium: '#909090',
    regular: '#E1E2DF',
    light: '#FFFFFF',
    secondaryPrimary: '#51D5F6',
  },
  white: '#fefefe',
  black: '#000000',
  disabledGray: '#b3b3b3',
  tableGray: '#dee2e6',
  danger: '#dc3545',
  success: '#28a745',
  warning: '#ffc107',
  green: '#00CA26',
  lightGray: '#EAEAEA',
  mediumGray: '#8D8D8D',
  darkGray: '#3F3E3E',
  red: '#f00',
  yellow: '#ffc107',
  lightRed: '#CF878E',
  inputColor: '#3F3E3E',
  linkRed: '#FF474e',
  lightBlue: '#d7ebf2'
};

// Still need to determine what the idea smMobile breakpoint will be
const breakpoints = {
  xsMobile: '320px',
  smMobile: '',
  mdMobile: '480px',
  lgMobile: '576px',
  smTablet: '768px',
  lgTablet: '992px',
  smDesktop: '1200px',
  lgDesktop: '1920px',
};

export default {
  colors,
  breakpoints,
  fonts: {
    heading: 'Roboto, sans-serif',
    paragraph: 'Roboto, sans-serif',
    secondary: 'GT America Trial, sans-serif',
  },
  borderRadius: '0.25rem',
  boxShadow: `0px 0px 8px ${colors.secondary.medium}`,
  inputs: {
    border: `0.0625rem solid #ced4da`,
  },
  labels: {
    fontWeight: 700,
  },
};
