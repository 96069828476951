import React from 'react';
import PropTypes from 'prop-types';
import { Col, Container as BaseContainer, Row } from 'reactstrap';
import { H1, P } from 'components/Typography';
import styled from 'styled-components/macro';
import { theme } from 'styled-tools';
import { Link } from 'react-router-dom';
import { getRedirectPath } from 'utils/roles';
import { SUPPORT_EMAIL } from 'utils/consts';
import HelmetTitle from '../../components/HelmetTitle/HelmetTitle';

const Container = styled(BaseContainer)`
  margin-top: 2rem;
  background: ${theme('colors.white')};
  box-shadow: ${theme('boxShadow')};
  border-radius: ${theme('borderRadius')};
  margin-bottom: 1.25rem;
  padding: 1em;
`;

function ErrorPage({ title, pageHeader, bodyText }) {
  return (
    <div>
      <HelmetTitle title={title} />
      <Container>
        <Row>
          <Col>
            <H1>{pageHeader}</H1>
            <P>
              {bodyText} Please try again or contact{' '}
              <a href={`mailto://${SUPPORT_EMAIL}`}>{SUPPORT_EMAIL}</a> for assistance.
            </P>
            <P>
              <Link to={getRedirectPath()}>Go back to dashboard</Link>
            </P>
          </Col>
        </Row>
      </Container>
    </div>
  );
}

ErrorPage.propTypes = {
  title: PropTypes.string.isRequired,
  pageHeader: PropTypes.string.isRequired,
  bodyText: PropTypes.string.isRequired,
};

export default React.memo(ErrorPage);
