import React from 'react';
import omit from 'lodash/omit';
import { MATCH_STATUS, ALERT_TYPES } from './consts';
import CheckIcon from 'components/Icons/CheckIcon';
import ExclamationIcon from 'components/Icons/ExclamationIcon';
import { getRedirectPath } from 'utils/roles';

// this function may need to be optimized
export const omitDeep = (obj, removeKey) => {
  let newObj = omit(obj, removeKey);
  for (const key in obj) {
    if (Array.isArray(newObj[key])) {
      newObj[key] = newObj[key].map(item => omitDeep(item, removeKey));
    } else if (newObj[key] && typeof newObj[key] === 'object') {
      newObj[key] = omitDeep(newObj[key], removeKey);
    }
  }
  return newObj;
};

export const getStatusMessage = status => {
  switch (status) {
    case MATCH_STATUS.MATCH:
      return (
        <>
          <CheckIcon role='presentation' /> Matched
        </>
      );
    case MATCH_STATUS.NOT_REGISTERED:
      return (
        <>
          <ExclamationIcon role='presentation' /> Needs to Register
        </>
      );
    case MATCH_STATUS.NOT_LINKED:
      return (
        <>
          <ExclamationIcon role='presentation' /> Not Linked
        </>
      );
    case MATCH_STATUS.NONE:
    default:
      return '';
  }
};

export function resizeArray(arr = [], size, defaultValue) {
  if (isNaN(size) || size === '') {
    return arr;
  }

  while (arr.length > size) {
    arr.pop();
  }
  while (arr.length < size) {
    arr.push(defaultValue);
  }
  return arr;
}

export function resizeFieldArray([name, size, defaultValue], state, { changeValue }) {
  changeValue(state, name, value => resizeArray(value, size, defaultValue));
}

export function capitalize(str) {
  if (typeof str === 'string') {
    return str.replace(/^\w/, c => c.toUpperCase());
  } else {
    return '';
  }
}

export function formatCsvArray(array, key) {
  return array.reduce((acc, item, index) => {
    const num = index + 1;
    Object.entries(item).forEach(entry => {
      if (entry[0] === '__typename') {
        return;
      }
      acc[`${key}${num}${entry[0]}`] = entry[1];
    });
    return acc;
  }, {});
}

export function setError(error, setFlashMessage, history) {
  setFlashMessage({
    type: ALERT_TYPES.DANGER,
    message: error.message.replace('GraphQL error: ', ''),
  });
  history.push(getRedirectPath());
}

export function buildValueMap(arr) {
  return arr.reduce((acc, i) => {
    acc[i.value] = i.label;
    return acc;
  }, {});
}
