import React, { useEffect, useState } from 'react';
import { getUserData, isAuthenticated, removeToken } from 'utils/auth';
import { getToken } from '../utils/auth';
import moment from 'moment';

export const AuthContext = React.createContext();

export function AuthProvider({ children }) {
  const [isAuth, setIsAuth] = useState(isAuthenticated());
  const [toggle, setToggle] = useState();
  const [logoutTimer, setLogoutTimer] = useState(null);
  const userData = getUserData();

  useEffect(() => {
    const expiration = userData?.exp;
    if (expiration && !logoutTimer) {
      const expirationMoment = moment(expiration * 1000);
      const tokenExpiration = expirationMoment.diff(moment().utc());

      const logoutTimeout = setTimeout(() => {
        removeToken();
        window.location.href = `/logout`;
      }, tokenExpiration);
      setLogoutTimer(logoutTimeout);
    }
  }, [userData]); // eslint-disable-line react-hooks/exhaustive-deps

  const flagChange = () => {
    setToggle(!toggle);
  };

  React.useEffect(() => {
    setIsAuth(!!getToken());
  }, [toggle]);

  return (
    <AuthContext.Provider
      value={{
        logoutTimer,
        setLogoutTimer,
        isAuthenticated: isAuth,
        updateAuthStatus: flagChange,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
}

export const AuthConsumer = AuthContext.Consumer;
