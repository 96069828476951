import React from 'react';
import { Link } from 'react-router-dom';
import styled from 'styled-components/macro';
import { theme } from 'styled-tools';
import { up } from 'styled-breakpoints';
import { Container } from 'reactstrap';
import { SUPPORT_EMAIL } from 'utils/consts';

const FooterWrapper = styled.footer`
  background: ${theme('colors.white')};
  padding: 1rem 0;
  font-size: 0.875;

  ${up('lgTablet')} {
    font-size: 1rem;
  }
`;

const FooterContainer = styled(Container)`
  display: flex;
  flex-wrap: wrap;
  flex-direction: column;
  font-size: 0.875rem;

  ${up('lgTablet')} {
    flex-direction: row;
  }
`;

const SupportEmail = styled.a`
  margin-left: auto;
`;

const FooterLeft = styled.div`
  color: ${theme('colors.primary')};
  margin-left: auto;
  margin-right: auto;

  ul {
    list-style-type: none;
    padding-left: 0;
    margin-bottom: 0;
    display: flex;
    align-items: center;
  }

  li + li {
    margin-left: 0.5rem;

    &:before {
      content: '|';
      padding-right: 0.5rem;
    }
  }

  ${up('lgTablet')} {
    margin-left: 0;
    display: inline-block;
    width: 40%;
  }
`;

const FooterRight = styled.div`
  display: flex;
  flex-direction: column;

  ${up('lgTablet')} {
    flex-direction: row;
    justify-content: flex-end;
  }
`;

const RightFooterWrapper = styled.div`
  display: inline-block;
  text-align: center;

  ${up('lgTablet')} {
    text-align: right;
    margin-left: 1rem;
  }
`;

const Address = styled.span`
  color: ${theme('colors.primary')};
`;

function Footer() {
  return (
    <FooterWrapper>
      <FooterContainer>
        <FooterLeft>
          <ul>
            <li>
              <a href='https://rentsafe.lease' target='_blank' rel='noopener nofollow noreferrer'>
                About Us
              </a>
            </li>
            <li>
              <Link to='/refund-policy'>Refund Policy</Link>
            </li>
            <li>
              <Link to='/privacy-policy'>Privacy Policy</Link>
            </li>
          </ul>
        </FooterLeft>
        <FooterRight>
          <RightFooterWrapper>
            <SupportEmail href={`mailto:${SUPPORT_EMAIL}`}>{SUPPORT_EMAIL}</SupportEmail>
          </RightFooterWrapper>
          <RightFooterWrapper>
            <Address>920 I ST NW #705, Washington DC, 20001</Address>
          </RightFooterWrapper>
        </FooterRight>
      </FooterContainer>
    </FooterWrapper>
  );
}

export default Footer;
