import styled, { css } from 'styled-components/macro';
import { theme, ifProp } from 'styled-tools';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faExclamationCircle } from '@fortawesome/free-solid-svg-icons';

const ExclamationIcon = styled(FontAwesomeIcon).attrs({
  icon: faExclamationCircle,
  alt: 'Attention',
})`
  color: ${theme('colors.warning')};
  margin-right: 0.375rem;

  ${ifProp(
    { type: 'list' },
    css`
      margin-top: 0.1875rem;
    `
  )}
`;

export default ExclamationIcon;
