import React from 'react';
import ErrorPage from './index';

function ServerErrorPage() {
  return (
    <ErrorPage
      title='500 Server Error'
      pageHeader='Something went wrong'
      bodyText='There was a problem while trying to load the page.'
    />
  );
}

export default React.memo(ServerErrorPage);
