import React, { lazy, Suspense } from 'react';
import { Redirect, Route, Switch } from 'react-router-dom';
import PrivateRoute from 'components/PrivateRoute';
import { ROLE_PROPERTY_MANAGER, ROLE_USER } from 'utils/roles';
import Loader from 'components/Loader';

const VerifyEmail = lazy(() => import('./routes/VerifyEmail'));
const NewProperty = lazy(() => import('./routes/Properties/NewProperty'));
const EditProperty = lazy(() => import('./routes/Properties/EditProperty'));
const IndexPage = lazy(() => import('routes/Index'));
const LoginPage = lazy(() => import('routes/Login'));
const LogoutPage = lazy(() => import('routes/Logout'));
const PropertyList = lazy(() => import('routes/PropertyManagerDashboards/PropertyList'));
const ApplicationList = lazy(() => import('routes/PropertyManagerDashboards/ApplicationList'));
const ApplicationView = lazy(() => import('routes/PropertyManagerDashboards/ApplicationView'));
const PasswordResetPage = lazy(() => import('routes/PasswordReset'));
const UserAccountPage = lazy(() => import('routes/UserAccount'));
const ApplicantRegistration = lazy(() => import('routes/Registration/Applicant'));
const PropertyManagerRegistration = lazy(() => import('routes/Registration/PropertyManager'));
const TermsAndConditions = lazy(() => import('routes/TermsAndConditions'));
const RefundPolicy = lazy(() => import('routes/RefundPolicy'));
const PrivacyPolicy = lazy(() => import('routes/PrivacyPolicy'));
const Application = lazy(() => import('routes/Application'));
const ApplicantDashboard = lazy(() => import('routes/ApplicantDashboard'));
const NotFoundPage = lazy(() => import('routes/ErrorPages/NotFoundPage'));
const ForbiddenPage = lazy(() => import('routes/ErrorPages/ForbiddenPage'));
const ServerErrorPage = lazy(() => import('routes/ErrorPages/ServerErrorPage'));
const PropertyLandingPage = lazy(() => import('routes/PropertyLandingPage'));

function Routes() {
  return (
    <Suspense fallback={<Loader />}>
      <Switch>
        <Route path='/login' component={LoginPage} exact />
        <Route path='/pm-portal' component={LoginPage} exact />
        <Route path='/logout' component={LogoutPage} exact />
        <Route path='/password-reset/:resetToken?' component={PasswordResetPage} exact />
        <Route path='/verify_email/:userId?/:token?' component={VerifyEmail} exact />
        <Route path='/find-property/:code' component={PropertyLandingPage} exact />

        <PrivateRoute path='/' component={IndexPage} exact role={ROLE_USER} />
        <PrivateRoute path='/account' component={UserAccountPage} exact role={ROLE_USER} />

        <PrivateRoute
          path='/property-manager-dashboard'
          component={PropertyList}
          exact
          role={ROLE_PROPERTY_MANAGER}
          redirectTo={'/pm-portal'}
        />
        <PrivateRoute
          path='/property-manager-dashboard/applications-list/:propertyId'
          component={ApplicationList}
          exact
          role={ROLE_PROPERTY_MANAGER}
          redirectTo={'/pm-portal'}
        />
        <PrivateRoute
          path='/property-manager-dashboard/application/:applicationId/:appDataId?'
          component={ApplicationView}
          exact
          role={ROLE_PROPERTY_MANAGER}
          redirectTo={'/pm-portal'}
        />

        <PrivateRoute path='/properties/new' component={NewProperty} exact role={ROLE_PROPERTY_MANAGER} />
        <PrivateRoute
          path='/properties/edit/:id'
          component={EditProperty}
          exact
          role={ROLE_PROPERTY_MANAGER}
        />

        <Route path='/renter-registration' component={ApplicantRegistration} exact />
        <Route
          path='/property-manager-registration'
          component={PropertyManagerRegistration}
          exact
        />
        <Route path='/terms-and-conditions' component={TermsAndConditions} exact />
        <Route path='/refund-policy' component={RefundPolicy} exact />
        <Route path='/privacy-policy' component={PrivacyPolicy} exact />

        {/* Applicant Dashboard */}
        <PrivateRoute path='/applicant-dashboard' component={ApplicantDashboard} role={ROLE_USER} />

        {/* Property Manager Dashboard */}

        {/* Application Process */}
        {/* A user must log in or create an account to start the application process */}
        <Route
          path='/application/:code/:status?'
          render={({ history, match: { url } }) => <Application history={history} url={url} />}
        />

        {/* error pages */}
        <Route path='/404' component={NotFoundPage} />
        <Route path='/403' component={ForbiddenPage} />
        <Route path='/500' component={ServerErrorPage} />
        <Redirect to='/404' />
      </Switch>
    </Suspense>
  );
}

export default Routes;
