import { useEffect } from 'react';
import PropTypes from 'prop-types';
import ReactGA from 'react-ga';
import { useHistory } from 'react-router-dom';
import { gaSetUserId, sendPageView } from 'utils/googleAnalytics';

const propTypes = {
  children: PropTypes.node,
  trackingId: PropTypes.string,
  history: PropTypes.shape({
    listen: PropTypes.func,
  }),
};

function GoogleAnalyticsListener({ children, trackingId, userId }) {
  const history = useHistory();

  useEffect(() => {
    if (trackingId) {
      ReactGA.initialize(trackingId, { gaOptions: { userId } });
      sendPageView(history.location);
      return history.listen(sendPageView);
    }
  }, [history, trackingId, userId]);

  useEffect(() => {
    if (trackingId && userId) {
      gaSetUserId(userId);
    }
  }, [userId, trackingId]);

  return children;
}

GoogleAnalyticsListener.propTypes = propTypes;

export default GoogleAnalyticsListener;
