import React from 'react';

export const withContext = (Component, Provider) => {
  return props => {
    return (
      <Provider>
        <Component {...props} />
      </Provider>
    );
  };
};
