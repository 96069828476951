import React from 'react';
import PropTypes from 'prop-types';
import { Helmet } from 'react-helmet';

const HelmetTitle = ({ title }) => (
  <Helmet>
    <title>{title}</title>
  </Helmet>
);

HelmetTitle.propTypes = {
  title: PropTypes.string,
};

export default HelmetTitle;
