import React, { useState } from 'react';

const FLASH_MESSAGE_FADE_TIMEOUT = 5000;

export const FlashContext = React.createContext();

export const FlashProvider = ({ children }) => {
  // setFlashMessage will take an object with `type` and `message`
  const [flashMessage, setFlashMessageState] = useState(null);

  const setFlashMessage = flashMessage => {
    setFlashMessageState(flashMessage);
    setTimeout(() => {
      setFlashMessageState(null);
    }, FLASH_MESSAGE_FADE_TIMEOUT);
  };

  return (
    <FlashContext.Provider
      value={{
        hasFlashMessage: !!flashMessage,
        flashMessage,
        setFlashMessage,
      }}
    >
      {children}
    </FlashContext.Provider>
  );
};

export const FlashConsumer = FlashContext.Consumer;
